@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap');

@font-face {
    font-family: "Chava-Reg";
    src: local("Chava-Reg"), url("Chava-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text Regular'), local('SFProText-Regular'),
    url('SFProText-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text Semibold'), local('SFProText-Semibold'),
    url('SFProText-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

.apply-font-chava-reg {
    font-family: Chava-Reg, serif;
}

html,
body {
    padding: 0;
    margin: 0;
    background-color: #14141F;
    color: #FFFFFF;
}

.heading1 {
    font-size: 38px;
    font-weight: 600;
    line-height: 46px;
    /*color: #FFFFFF;*/
}

.heading2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    /*color: #FFFFFF;*/
}
.heading3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    /*color: #FFFFFF;*/
}
.heading4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
}
.text-base {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #FFFFFFD9;
}
.text-normal {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    /*color: #FFFFFF;*/
}

.text-lg-normal {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.text-lg-strong {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #FFFFFFD9;
}

.text-lg-normal_damping {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #FFFFFFD9;
}


.text-strong {
    font-weight: 600;
    line-height: 22px;
    color: #FFFFFF;
}

.text-lg-strong {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #FFFFFF
}

.flex {
    display: flex;
}
.flex-row {
    display: flex;
    flex-direction: row;
}
.flex-column {
    display: flex;
    flex-direction: column;
}

.justify-content-space-between {
    justify-content: space-between;
}
.justify-content-flex-end {
    justify-content: flex-end;
}
.justify-content-flex-start {
    justify-content: flex-start;
}
.justify-content-center {
    justify-content: center;
}

.gap-8 {
    gap: 8px;
}

.gap-16 {
    gap: 16px;
}
.gap-24 {
    gap: 24px;
}

.ant-input, .ant-picker {
    /*padding: 4px 12px !important;*/
}

.mb-8 {
    margin-bottom: 8px;
}
.mb-16 {
    margin-bottom: 16px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-40 {
    margin-bottom: 40px;
}

.ant-divider-vertical {
    height: 100% !important;
}

.ant-progress {
    margin: 0 !important;
}

.ant-progress-inner {
    height: 6px !important;
}

.ant-empty .ant-empty-description {
    color: #fbfbff;
}

.ant-btn {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: none !important;
    box-shadow: 0 2px 0 0 #00000005;

}

.ant-radio-button-wrapper {
    padding: 1px 8px !important;
}

.ant-radio-button-wrapper span {
    line-height: 22px !important;
}

.border-gradient1 {
    display: flex;
    flex-direction: column;
    padding: 2px;
    gap: 24px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border-width: 2px 0 0 2px;
    border-style: solid;
    border-color: transparent;
    background: linear-gradient(0deg, rgb(28, 28, 39) 100%, rgb(28, 28, 39) 100%) padding-box padding-box,
    linear-gradient(150deg, rgb(82, 151, 255) 0%, rgba(82, 151, 255, 0) 55%) border-box border-box;
    border-radius: 16px;
    width: 32.66%;
}

.grounded-radiants {
    position: relative;
    border: 4px solid transparent;
    border-radius: 16px;
    background: linear-gradient(orange, violet);
    background-clip: padding-box;
    padding: 10px;
}

.grounded-radiants::after {
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    background: linear-gradient(red, blue);
    content: '';
    z-index: -1;
    border-radius: 16px;
}
.ant-btn-default {
    border: 1px solid #424242;
}

.ant-tag {
    padding: 0 8px !important;
    margin-inline-end: 0 !important;
}

.ant-modal .ant-qrcode {
    background-color: transparent;
}

.ant-modal-content {
    padding: 16px 32px 32px !important;
}
.ant-modal-close {
    width: 24px !important;
    height: 24px !important;
    top: 16px !important;
    right: 32px !important;
}
.ant-modal-footer {
    display: none !important;
}

.ant-form-item-label label {
    color: #FFFFFFD9 !important;
}

.ant-form-item-extra {
    padding-top: 2px;
}


.custom-skeleton-elements > div > ul > li {
    height: 48px !important;
}

.custom-skeleton-elements.filters {
    position: absolute;
    top: 100px;
}

.ant-dropdown-menu {
    padding: 4px !important;
}
.ant-dropdown-trigger {
    cursor: pointer;
}

.ant-dropdown-menu-item {
    padding: 0 !important;
    overflow: hidden !important;
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

